import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TypeFormSignUp } from 'app/pages/Introduction';
import { TextError } from 'app/components/elements/TextError';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { InputSelectInfiniteScroll } from 'app/components/elements/InputSelectInfiniteScroll';

type FormAddressProps = {
  isMobile: boolean;
  activeStep: number;
};
export const FormAddress = ({ isMobile }: FormAddressProps) => {
  const {
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<TypeFormSignUp>();
  const [countriesAll, setCountriesAll] = useState<SelectOptions[]>();
  const [citiesALl, setCitiesAll] = useState<SelectOptions[]>();
  const [chooseCity, setChooseCity] = useState<SelectOptions>(
    getValues('city') || {
      label: '',
      value: ''
    }
  );
  const fetchDataCountries = useCallback(() => {
    const ListCountries = Country.getAllCountries()?.map((item: ICountry) => ({
      value: item.isoCode,
      label: item.name,
      flag: item.flag
    }));
    setCountriesAll(ListCountries);
  }, []);
  useEffect(() => {
    fetchDataCountries();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!getValues('country')) return;
    fetchCity(getValues('country').value as string);
    //eslint-disable-next-line
  }, [getValues('country')]);

  const fetchCity = useCallback((countryCode: string) => {
    if (!countryCode) return;
    const listCities = City.getCitiesOfCountry(countryCode)?.map((item: ICity) => ({
      value: `${item.name} (${item.stateCode})`,
      label: `${item.name} (${item.stateCode})`,
      longitude: item.longitude,
      latitude: item.latitude
    }));
    if (!listCities) return;
    setCitiesAll(listCities);
    setChooseCity(listCities[0]);
    return listCities;
  }, []);

  const handleChangeCountry = useCallback((option: SelectOptions) => {
    if (!option) {
      setCitiesAll([]);
      return;
    }
    setValue('country', option);

    const cities = fetchCity(option.value as string);
    if (cities) {
      setValue('city', cities[0]);
    }
    //eslint-disable-next-line
  }, []);

  const handleChangeCity = useCallback((option: SelectOptions) => {
    if (!option) return;
    setValue('city', option);
    setChooseCity(option);
    //eslint-disable-next-line
  }, []);

  return (
    <Stack minWidth={isMobile ? '100%' : '402px'} alignItems={'center'} mt={5} gap={'19px'}>
      <Typography color={'#770BA1'} fontSize={20} fontWeight={700}>
        Where do you live?
      </Typography>
      <Stack width={'100%'} gap={1}>
        <InputSelectInfiniteScroll
          label={'Choose a country'}
          onChange={handleChangeCountry}
          options={countriesAll || []}
          value={getValues('country')}
        />
        <TextError text={errors?.country?.message || ''} />
      </Stack>
      <Stack width={'100%'} gap={1}>
        <InputSelectInfiniteScroll
          label={'Choose a city'}
          onChange={handleChangeCity}
          options={citiesALl || []}
          value={getValues('city') || chooseCity}
        />
        {errors?.city?.message && <TextError text={errors?.city?.message} />}
      </Stack>
    </Stack>
  );
};
