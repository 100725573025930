import { Input, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { TextError } from 'app/components/elements/TextError';
import { ReactNode, useRef, useState } from 'react';
import { StyledParams } from 'types/common';
export const maxFileSize = 50 * 1024 * 1024;

type InputFileProps = {
  label?: string;
  colorlabel?: string;
  isCenter?: boolean;
  endAdornment?: ReactNode;
  handleChangeFile: (file: File) => void;
  selectedFile?: File;
  acceptType?: string;
  placeholder?: string;
};
export const InputFile = ({
  label,
  colorlabel,
  isCenter,
  endAdornment,
  selectedFile,
  handleChangeFile,
  acceptType,
  placeholder
}: InputFileProps) => {
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxFileSize) {
        setError('File size exceeds 50MB. Please select a smaller file.');
        return;
      }
      setError(null);
      handleChangeFile(file);
    }
  };
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <Stack
      gap={{ xs: 1.25, md: 2.125 }}
      alignItems={isCenter ? 'center' : 'flex-start'}
      width={{ xs: '100%', md: '100%' }}>
      {label && (
        <TextStyled
          fontSize={{ xs: '16px', md: '20px' }}
          textAlign={'center'}
          colorlabel={colorlabel || '#697D21'}>
          {label}
        </TextStyled>
      )}
      <input
        accept={acceptType || ''}
        ref={fileInputRef}
        hidden
        onChange={handleFileChange}
        type="file"
      />
      <InputStyled
        onClick={handleFileClick}
        disableUnderline
        type="text"
        value={selectedFile ? selectedFile.name : ''}
        readOnly
        endAdornment={endAdornment}
        startAdornment={
          <InputAdornment position="start">
            <Stack flexDirection={'row'} gap={1.25}>
              <img alt={'icon'} src={'/icons/icon_upload_file.svg'} />
              {!selectedFile && (
                <Typography
                  fontSize={{ xs: '16px', md: '20px' }}
                  textAlign={'center'}
                  fontWeight={700}
                  color={'#697D21'}>
                  {placeholder || 'Add Attachment'}
                </Typography>
              )}
            </Stack>
          </InputAdornment>
        }
      />
      {error && <TextError text={error} />}
    </Stack>
  );
};
const TextStyled = styled(Typography)(({ colorlabel }: { colorlabel?: string }) => ({
  fontWeight: 700,
  lineHeight: 'normal',
  color: colorlabel
}));

const InputStyled = styled(Input)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.background.lightness2}`,
  boxShadow: `4px 4px 0px 0px  ${theme.colors.background.lightness2}`,
  background: theme.colors.background.lightness1,
  height: '52px',
  width: '100%',
  padding: '12px 16px',
  borderRadius: '12px',
  fontSize: '16px',
  color: theme.colors.text.base,
  '&:hover': {
    cursor: 'pointer'
  }
}));
