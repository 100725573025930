import { Dialog, IconButton, Stack, styled, Typography } from '@mui/material';
import { FlowerPointModal } from 'app/components/elements/icons/FlowerPointModal';
import { PrimaryButton, SecondaryButton } from './ButtonCustom';

type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  textTitle: string;
  textContent: string;
  textButton: string;
  isPlusPoint?: boolean;
  colorFlowerPoint?: string;
  textButtonClose?: string;
};
export const DialogConfirm = ({
  isOpen,
  onClose,
  onSubmit,
  textContent,
  textTitle,
  textButton,
  isPlusPoint,
  textButtonClose,
  colorFlowerPoint
}: DialogConfirmType) => {
  return (
    <Dialog open={isOpen} sx={{ borderRadius: '20px' }}>
      <WrapperDialog width={'100%'}>
        <Stack width={'100%'} flexDirection={'row'} p={1.375} justifyContent={'space-between'}>
          <Stack width={'33px'} />
          <Stack>{isPlusPoint && <FlowerPointModal color={colorFlowerPoint} />}</Stack>
          <IconButton onClick={onClose} sx={{ width: '40px', height: '40px' }}>
            <img src={'/icons/icon_modal_close.svg'} />
          </IconButton>
        </Stack>
        <Stack
          width={'100%'}
          px={{ xs: 2.5, md: 2 }}
          pb={{ xs: 3, md: 6.25 }}
          alignItems={'center'}
          gap={{ xs: 2, md: 2.5 }}>
          <TitleStyled fontSize={{ xs: '24px', md: '30px' }}>{textTitle}</TitleStyled>
          <ContentStyled px={{ xs: 1, md: 2.875 }}>{textContent}</ContentStyled>
          <PrimaryButton
            label={textButton}
            onClick={onSubmit}
            icon={<img src={'/icons/icon_flower.png'} />}
            fullWidth
            fontSizeText={'20px'}
          />
          {textButtonClose && (
            <SecondaryButton
              label={textButtonClose}
              onClick={onClose}
              fullWidth
              fontSizeText={'20px'}
            />
          )}
        </Stack>
      </WrapperDialog>
    </Dialog>
  );
};

const WrapperDialog = styled(Stack)(() => ({
  borderRadius: '20px'
}));
const TitleStyled = styled(Typography)(() => ({
  fontWeight: 700,
  color: '#D090D7',
  textAlign: 'center'
}));
const ContentStyled = styled(Typography)(() => ({
  fontSize: '20px',
  color: '#000',
  fontWeight: 400,
  whiteSpace: 'pre-line',
  textAlign: 'center'
}));
