import { Stack, styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ImageCircleUser } from 'app/components/elements/ImageCircleUser';
import { CustomPagination } from 'app/components/elements/Pagination';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import { RecentPostsCardQuestionsAnswer } from 'app/pages/DashboardPage/components/RecentPostsCardQuestionsAnswer';
import axios from 'axios';
import { SIZE_PAGE_RECENT_POST } from 'constants/paging';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';
import { User } from 'types/User';

const SIZE_PAGE = 10;
export const RecentPostsTab = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [listUsers, setListUsers] = useState<Questions[]>([]);
  const [pageBloombassador, setPageBloombassador] = useState<number>(1);
  const [listBloombassador, setListBloombassador] = useState<User[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_PAGE_RECENT_POST
  });

  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ tab: 'recent-posts', page: value.toString() });
    // eslint-disable-next-line
  }, []);
  const fetchDataPost = useCallback(async (page: string | number) => {
    const res = await axios.get(`/posts/recent?page=${page}&size=${SIZE_PAGE_RECENT_POST}`);
    setListUsers(res.data.result);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
  }, []);
  useEffect(() => {
    fetchDataPost(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  const fetchListPost = useCallback(async () => {
    const { data: res } = await axios.get(
      `/users/bloombassador?page=${pageBloombassador}&size=${SIZE_PAGE}`
    );

    const newData = res.users;
    if (newData.length > 0) {
      setListBloombassador([...listBloombassador, ...newData]);
      setPageBloombassador((prevState) => prevState + 1);
      return;
    }
    setHasMore(false);
    // eslint-disable-next-line
  }, [pageBloombassador]);
  useEffect(() => {
    fetchListPost();
    // eslint-disable-next-line
  }, []);
  return (
    <Stack
      width={'100%'}
      justifyContent={'space-between'}
      flexDirection={{ md: 'column', lg: 'row' }}
      gap={2.5}>
      <WrapperLeft width={{ xs: '100%', md: 710, xl: '100%' }} minHeight={'874px'}>
        {listUsers &&
          listUsers?.map((item, index) => (
            <Stack key={index}>
              <RecentPostsCardQuestionsAnswer infoCard={item} />
            </Stack>
          ))}
        <Stack
          mt={2.5}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          width={'100%'}
          alignItems={'center'}
          gap={{ xs: 2, md: 'unset' }}>
          {pagingInfo.pageCount !== 0 && (
            <CustomPagination
              page={pagingInfo.currentPage}
              count={pagingInfo.pageCount}
              onChangePage={handleChangePage}
            />
          )}
        </Stack>
      </WrapperLeft>
      <WrapperRight width={{ xs: '100%', md: 360 }} py={2.5} pl={2.5} gap={2.5} height={800}>
        <Typography
          fontFamily={`'MyFont', sans-serif`}
          fontSize={'34px'}
          fontWeight={700}
          color={'#fff'}>
          Bloombassadors
        </Typography>
        <InfiniteScroll
          height={680}
          dataLength={listBloombassador.length}
          next={fetchListPost}
          hasMore={
            listBloombassador.length >= SIZE_PAGE * (pageBloombassador - 1) ? hasMore : false
          }
          loader={<CircularProgress />}>
          {listBloombassador.map((user, index) => (
            <Stack key={index} pr={2.5}>
              <Stack
                flexDirection={'row'}
                gap={2}
                alignItems={'center'}
                pb={3}
                pt={2.5}
                borderBottom={'1px solid #D7D7D7'}>
                <Stack
                  minWidth={'90px'}
                  width={'90px'}
                  height={'90px'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/profile/${user.id}`)}>
                  <ImageCircleUser url={user.avatar} />
                </Stack>
                <Stack>
                  <Typography fontSize={'24px'} fontWeight={700} color={'#fff'}>
                    <TruncateTableText
                      isPadding={false}
                      textProps={{ fontSize: '24px', fontWeight: 700 }}
                      text={user.name}
                    />
                  </Typography>
                  <TextSubjectId subjectId={user?.major?.id} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </InfiniteScroll>
      </WrapperRight>
    </Stack>
  );
};
const WrapperLeft = styled(Stack)(() => ({
  borderRadius: '20px',
  border: '5px solid #D090D7',
  background: 'linear-gradient(180deg, rgba(255, 141, 26, 0.20) 0%, rgba(255, 90, 159, 0.20) 100%)',
  padding: '10px 20px'
}));
const WrapperRight = styled(Stack)(() => ({
  borderRadius: '20px',
  border: '7px solid #CF90D6',
  backgroundImage: 'linear-gradient(180deg, #FFF 0%, #FF2E86 0.01%, #FF8D1A 100%)'
}));
