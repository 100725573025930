import { IconButton, Stack, styled, Typography } from '@mui/material';
import { CommentBlog } from 'app/components/elements/CommentBlog';
import { IconWatchFlower } from 'app/components/elements/icons/IconWatchFlower';
import { NoteBlog } from 'app/components/elements/NoteBlog';
import axios from 'axios';
import { useFlash } from 'contexts/flash';
import dayjs from 'dayjs';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { isEmpty } from 'lodash';
import { StyledParams } from 'types/common';
import { Comment, Questions } from 'types/questions';
import { AnimationLike } from 'utils/animationLike';
import { renderColorSubject } from 'utils/renderColorSubject';
import { IconArrow } from 'app/components/elements/icons/IconArrow';
import { IconFolder } from 'app/components/elements/icons/IconFolder';
import { useCallback, useMemo, useState } from 'react';
import { VideoCustom } from 'app/components/elements/VideoCustom';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { ImageCircleUser } from 'app/components/elements/ImageCircleUser';

export type BlogPostProps = {
  blog: Questions;
};
export const BlogPost = ({ blog }: BlogPostProps) => {
  const { setFlash } = useFlash();
  const [isLike, setIsLike] = useState<boolean>(blog.isLiked);
  const [showAnimationLike, setShowAnimationLike] = useState<boolean>(false);
  const [isShowComment, setIsShowComment] = useState<boolean>(true);
  const [isShowNote, setIsShowNote] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<File>();
  const [comment, setComment] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>(blog?.comments || []);
  const [errorComment, setErrorComment] = useState<string>('');

  const renderInfoSubject = useMemo(() => {
    if (!blog.subject.id) return;
    return renderColorSubject(blog.subject.id);
  }, [blog.subject.id]);
  const handleChangeFile = useCallback((file: File) => {
    setAttachment(file);
  }, []);

  const handleAddComment = useCallback(async () => {
    if (!comment) {
      setErrorComment('Required Field');
      return;
    }
    const formData = new FormData();
    let attachmentId;
    if (attachment) {
      formData.append('file', attachment);
      attachmentId = await axios.post('/storages/comments', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }

    const payload = {
      content: comment,
      attachmentId: attachmentId ? attachmentId.data.record.id : undefined
    };
    const res = await axios.post(`/questions/${blog.id}/comments`, payload);
    setFlash({ type: 'success', message: 'Add comment successfully' });
    const resComment = res.data.comment;
    if (!resComment) return;
    setComments((prevState) => [...prevState, resComment]);
    setIsShowComment(false);
    setComment('');
    setAttachment(undefined);
    // eslint-disable-next-line
  }, [comment, attachment, blog]);

  const handleChangeText = useCallback((value: string) => {
    setComment(value);
    setErrorComment('');
  }, []);

  const onShowNote = useCallback(async () => {
    setIsShowComment(false);
    if (isShowNote) {
      setIsShowNote(false);
      return;
    }
    setIsShowNote(true);
  }, [isShowNote]);

  const { asyncCallback: asyncCallbackAddComment, isLoading: isLoadingComment } = useAsyncCallback(
    handleAddComment,
    []
  );

  const hanleAddNote = useCallback(
    async (value: string) => {
      await axios.put(`/questions/${blog.id}/notes`, { content: value });
      setFlash({ type: 'success', message: 'Add note successfully' });
    },
    [blog.id]
  );

  const handleLike = useCallback(async () => {
    await axios.post(`/questions/${blog.id}/like`);
    setIsLike(!isLike);
    setShowAnimationLike(true);
    setTimeout(() => {
      setShowAnimationLike(false);
    }, 1000);
  }, [isLike, blog.id]);

  const { asyncCallback: asyncHandleLike } = useAsyncCallback(handleLike, []);
  return (
    <Stack gap={2.5} mb={7.5} mr={{ md: 2.5, xs: 0 }}>
      <TextStyled fontSize={{ xs: 24, md: 40 }} color={renderInfoSubject?.color}>
        {blog.title}
      </TextStyled>
      <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 2.5, md: 0 }}>
        <Stack flexDirection={{ xs: 'column', md: 'row' }}>
          <WrapperVideo
            sx={{
              maxWidth: window.innerWidth < 600 ? window.innerWidth - 40 : 'unset',
              borderColor: renderInfoSubject?.color,
              background: '#000',
              position: 'relative'
            }}>
            <VideoCustom src={blog?.record.url} />
            <AnimationLike startAnimation={showAnimationLike} />
          </WrapperVideo>
          <Stack
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            gap={0.75}
            ml={1.625}
            position={'relative'}
            flexDirection={{ xs: 'row', md: 'column' }}>
            <IconButton disabled={isLike} onClick={asyncHandleLike}>
              <IconWatchFlower color={isLike ? '#FF006B' : ' #D090D7'} />
            </IconButton>
            <IconButton
              onClick={() => {
                setIsShowNote(false);
                setIsShowComment(!isShowComment);
              }}>
              <img alt={'image-mess'} src={'/icons/icon_watch_message.svg'} />
            </IconButton>
            <IconButton onClick={onShowNote}>
              <img alt={'image-pen'} src={'/icons/icon_watch_pen.svg'} />
            </IconButton>
          </Stack>
        </Stack>
        {(isShowComment || isShowNote) && (
          <WrapperComment pr={{ xs: 2.5, md: 0 }}>
            {isShowComment && (
              <CommentBlog
                isAnswer={!isEmpty(blog.answer)}
                onChangeAttachment={handleChangeFile}
                fileName={attachment?.name}
                onChangeText={handleChangeText}
                onComment={asyncCallbackAddComment}
                errorComment={errorComment}
                questionsId={blog.id}
                isLoadingComment={isLoadingComment}
              />
            )}
            {isShowNote && <NoteBlog onNote={hanleAddNote} initValue={blog?.note?.content || ''} />}
          </WrapperComment>
        )}
      </Stack>
      <Stack gap={2.5}>
        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
          <WrapperAvatar sx={{ borderColor: renderInfoSubject?.color }}>
            <ImageCircleUser url={blog?.user?.avatar} />
          </WrapperAvatar>
          <Stack>
            <TextName color={renderInfoSubject?.color}>{blog.user.name}</TextName>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <TextDesc color={renderInfoSubject?.color}>Subject:</TextDesc>
              <TextSubjectId isEllipsis subjectId={blog.subject.id} />
            </Stack>
            <Typography
              style={{
                color: renderInfoSubject?.color,
                fontSize: 16
              }}>
              Created: {dayjs(blog.createdAt).format('YYYY-MM-DD')}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography
            fontWeight={700}
            fontFamily={`'MyFont', sans-serif`}
            fontSize={'28px'}
            lineHeight={'normal'}
            color={renderInfoSubject?.color}>
            {blog.answer || blog?.comments.length !== 0
              ? 'Response from Bloombassador:'
              : 'No Responses Yet'}
          </Typography>
        </Stack>
        <Stack>
          {!isEmpty(blog.answer) && (
            <Stack flexDirection={'row'}>
              <IconArrow subjectId={blog.subject.id} />
              <Stack gap={2.5} ml={3.125} m={2.5}>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <WrapperVideoAnswer
                    sx={{ borderColor: renderInfoSubject?.color, background: '#000' }}>
                    <VideoCustom src={blog.answer.record.url} />
                  </WrapperVideoAnswer>
                  {blog.answer.attachment.fileName && (
                    <Stack alignItems={'center'}>
                      <IconButton
                        sx={{ padding: '16px 10px', width: '80px' }}
                        onClick={() => window.open(blog.answer.attachment.url, '_blank')}>
                        <IconFolder subjectId={blog.subject.id} />
                      </IconButton>
                      <Typography
                        px={2.5}
                        textAlign={'center'}
                        maxWidth={'200px'}
                        whiteSpace={'pre-line'}
                        fontSize={'16px'}
                        fontWeight={500}
                        color={renderInfoSubject?.color}>
                        {blog.answer.attachment.fileName}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                  <WrapperAvatar sx={{ borderColor: renderInfoSubject?.color }}>
                    <ImageCircleUser url={blog?.answer?.user?.avatar} />
                  </WrapperAvatar>
                  <Stack flexWrap={'wrap'}>
                    <TextName color={renderInfoSubject?.color}>{blog.answer.user.name}</TextName>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                      <TextDesc color={renderInfoSubject?.color}>Major:</TextDesc>
                      <TextSubjectId isEllipsis subjectId={blog.subject.id} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
          {comments.length !== 0 &&
            comments.map((item) => (
              <Stack flexDirection={'row'} key={item.id}>
                <IconArrow subjectId={blog.subject.id} />
                <Stack width={'85%'} gap={2.5} m={2.5}>
                  <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                    <WrapperAvatar sx={{ borderColor: renderInfoSubject?.color }}>
                      <ImageCircleUser url={item?.user?.avatar} />
                    </WrapperAvatar>
                    <Stack width={'65%'}>
                      <TextName
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%'
                        }}
                        color={renderInfoSubject?.color}>
                        {item?.user?.name}
                      </TextName>
                      {item?.user?.major && (
                        <Stack
                          flexWrap={'wrap'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap={1}>
                          <TextDesc color={renderInfoSubject?.color}>Major:</TextDesc>
                          <TextSubjectId isEllipsis subjectId={item.user.major.id} />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Typography
                    fontSize={'18px'}
                    lineHeight={'20,7px'}
                    color={'#686868'}
                    maxWidth={'336px'}
                    whiteSpace={'pre-line'}>
                    {item.content}
                  </Typography>
                  {item?.attachment?.fileName && (
                    <Stack>
                      <IconButton
                        sx={{ padding: '16px 10px', width: '80px' }}
                        onClick={() => window.open(item?.attachment?.url, '_blank')}>
                        <IconFolder subjectId={blog.subject.id} />
                      </IconButton>
                      <Typography
                        px={2.5}
                        maxWidth={'400px'}
                        whiteSpace={'pre-line'}
                        fontSize={'16px'}
                        fontWeight={500}
                        color={renderInfoSubject?.color}>
                        {item?.attachment?.fileName}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
const TextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 'normal',
  fontFamily: `'MyFont', sans-serif`
}));
const WrapperVideo = styled(Stack)(() => ({
  border: '20px solid',
  borderRadius: '20px',
  justifyContent: 'center'
}));
const WrapperAvatar = styled(Stack)(() => ({
  width: '64px',
  height: '64px',
  border: '3px solid',
  borderRadius: '50%'
}));
const TextName = styled(Typography)(({ theme }: StyledParams) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: 'normal',
  fontFamily: `'MyFont', sans-serif`,
  [theme.breakpoints.down(800)]: {
    fontSize: '18px'
  }
}));
const TextDesc = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: 'normal',
  fontFamily: `'MyFont', sans-serif`
}));
const WrapperVideoAnswer = styled(Stack)(() => ({
  width: '322px',
  height: '349px',
  border: '15px solid',
  borderRadius: '20px'
}));

const WrapperComment = styled(Stack)(({ theme }: StyledParams) => ({
  width: '340px',
  marginLeft: 20,
  [theme.breakpoints.down(900)]: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    height: '400px'
  }
}));
