import { Button, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { FlowerPoint } from 'app/components/elements/icons/FlowerPoint';
import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { ImageUser } from 'app/components/elements/ImageUser';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import { ROLES } from 'app/pages/Introduction';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import compact from 'lodash/compact';
import { StorageServices } from 'services/storage';
import { StyledParams } from 'types/common';
import { User } from 'types/User';
import { renderColorSubject } from 'utils/renderColorSubject';
import { resolveParenthesesContent } from 'utils/resolveParenthesesContent';
import { useAuth } from 'contexts/user';
import { unregister } from '../../../../serviceWorkerRegistration';

const storage = new StorageServices();

type InfoUserProps = {
  user: User;
};

export const InfoUser = ({ user }: InfoUserProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const handleLogout = useCallback(() => {
    unregister();
    storage.removeAccessToken();
    storage.removeRefreshToken();
    setUser(null);
    navigate('/login');
  }, [unregister]);
  return (
    <WrapperInfo>
      <Stack
        flexDirection={'row'}
        height={'100%'}
        alignItems={'center'}
        gap={2.75}
        flex={user.role === ROLES.BLOOMBASSADOR ? 1 : 2}
        pl={{ xs: 0, md: 2.5 }}>
        <WrapperAvatar>
          <ImageUser isCircle url={user?.avatar} subjects={user.subjects} />
        </WrapperAvatar>
        <Stack gap={0.75}>
          <TruncateTableText
            isPadding={false}
            textProps={{ fontSize: '24px', fontWeight: 700 }}
            text={user.name}
            colorText={'#973DAD'}
          />
          <TextTitle>{user.role}</TextTitle>
          <TextTitle>{`${compact([
            resolveParenthesesContent(user.address.city),
            user.address.country
          ]).join(', ')}`}</TextTitle>
          <Stack flexDirection={'row'} mt={-0.3} alignItems={'flex-start'} gap={1}>
            <Stack flexDirection={{ xs: 'column', sm: 'row' }} flexWrap={'wrap'}>
              {user.subjects.map((subject, index: number) => (
                <WrapperListInfo key={subject.id} gap={1}>
                  {index === 0 && <TextTitle mr={0.5}>Interests: </TextTitle>}
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    <IconFlowerCustom subjectId={subject?.id} />
                    <Typography
                      fontSize={{ xs: '14px', lg: '18px' }}
                      fontWeight={400}
                      whiteSpace={'pre-line'}
                      color={renderColorSubject(Number(subject?.id))?.color || '#0000'}>
                      {renderColorSubject(Number(subject?.id))?.label}
                    </Typography>
                    {user.subjects.length !== index + 1 && (
                      <Typography fontSize={'20px'} color={'#686868'} mr={0.5}>
                        ,
                      </Typography>
                    )}
                  </Stack>
                </WrapperListInfo>
              ))}
            </Stack>
          </Stack>
          <WrapperButton>
            <ButtonStyled onClick={() => navigate(`/profile`)}>
              <TextButtonStyled>Edit Profile</TextButtonStyled>
            </ButtonStyled>
            <ButtonLogout onClick={handleLogout}>
              <TextButtonStyled>Logout</TextButtonStyled>
            </ButtonLogout>
          </WrapperButton>
        </Stack>
      </Stack>
      <Stack flex={1} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={5}>
        <Stack gap={3.5} alignItems={'center'}>
          <FlowerPoint
            color={'#F22369'}
            point={user.points.interactPoint.toString()}
            fontSizeTextPoint={isMobile ? '24px' : '36px'}
            size={{ width: isMobile ? '127px' : '163px', height: isMobile ? '122px' : '163px' }}
          />
          {!isMobile && (
            <ButtonPoint sx={{ background: '#F22369 !important' }}>
              <TextButtonPoint>Videos Interacted With</TextButtonPoint>
            </ButtonPoint>
          )}
        </Stack>
        {user.role === ROLES.BLOOMBASSADOR && (
          <Stack gap={3.5} alignItems={'center'}>
            <FlowerPoint
              fontSizeTextPoint={isMobile ? '24px' : '36px'}
              size={{ width: isMobile ? '127px' : '163px', height: isMobile ? '122px' : '163px' }}
              color={'#48C2F9'}
              point={user.points.earnedServiceHours.toString()}
            />
            {!isMobile && (
              <ButtonPoint
                sx={{
                  background: '#48C2F9 !important'
                }}>
                <TextButtonPoint>Earned Service Hours</TextButtonPoint>
              </ButtonPoint>
            )}
          </Stack>
        )}
      </Stack>
    </WrapperInfo>
  );
};
const WrapperInfo = styled(Stack)(({ theme }: StyledParams) => ({
  background: 'linear-gradient(180deg, rgba(255, 141, 26, 0.20) 0%, rgba(255, 90, 159, 0.20) 100%)',
  width: '100%',
  minHeight: '315px',
  borderRadius: '20px',
  border: '2px solid #D090D7',
  boxShadow: '4px 4px 0px 0px #D090D7',
  overflowX: 'auto',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(1350)]: {
    flexDirection: 'column',
    maxWidth: '100%',
    gap: '20px',
    padding: '20px 10px'
  }
}));
const TextTitle = styled(Typography)(({ theme }: StyledParams) => ({
  fontWeight: 700,
  color: '#770BA1',
  fontSize: '18px',
  lineHeight: 'normal',
  [theme.breakpoints.down(800)]: {
    fontSize: '14px'
  }
}));
const ButtonStyled = styled(Button)(({ theme }: StyledParams) => ({
  borderRadius: '10px',
  background: '#770BA1 !important',
  width: '126px',
  height: '40px',
  [theme.breakpoints.down(800)]: {
    width: '100%',
    height: '30px'
  }
}));
const ButtonLogout = styled(ButtonStyled)(() => ({
  background: '#D090D7 !important'
}));
const TextButtonStyled = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  color: '#fff',
  fontFamily: 'Helvetica',
  textTransform: 'capitalize'
}));
const ButtonPoint = styled(Button)(() => ({
  borderRadius: '20px',
  background: '#48C2F9',
  width: '200px',
  height: '40px'
}));
const TextButtonPoint = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  color: '#fff',
  fontFamily: 'Helvetica',
  textTransform: 'capitalize'
}));
const WrapperAvatar = styled(Stack)(({ theme }: StyledParams) => ({
  minWidth: '215px',
  maxWidth: '215px',
  width: '100%',
  height: '215px',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down(800)]: {
    minWidth: '45%',
    maxWidth: '45%',
    height: 'auto'
  }
}));
const WrapperButton = styled(Stack)(({ theme }: StyledParams) => ({
  flexDirection: 'row',
  gap: 2,
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column'
  }
}));
const WrapperListInfo = styled(Stack)(({ theme }: StyledParams) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  [theme.breakpoints.down(390)]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));
