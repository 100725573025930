import { Stack, Typography } from '@mui/material';
type LogoProps = {
  isDesc?: boolean;
};
export const Logo = ({ isDesc }: LogoProps) => {
  return (
    <Stack alignItems={'center'}>
      <Stack width={{ sm: '370px', xs: '247px' }}>
        <img width={'100%'} height={'100%'} alt={'logo'} src={'/images/logo-login.png'} />
      </Stack>
      {isDesc && (
        <Typography
          py={1}
          whiteSpace={'pre-line'}
          textAlign={'center'}
          mt={1}
          fontSize={'16px'}
          color={'#000'}>
          The SuperBloom progressive web app, the next step for BloomBox Design Labs, will act as a
          catalyst{'\n'} to support students around the world reaching full bloom by creating a
          community of collaboration and{'\n'} idea exchange in a 1-minute video library format.
          Content is tailored to student interest, allowing{'\n'} learners to go deep into the
          topics they care about most.
        </Typography>
      )}
      <Typography mt={1} fontSize={'14px'} color={'#770BA1'}>
        by BloomBox Design Labs
      </Typography>
    </Stack>
  );
};
