import { Button, Stack, styled, Typography } from '@mui/material';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogPost } from 'app/components/elements/BlogPost';
import { Questions } from 'types/questions';

export const QuestionsPage = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState<Questions>();
  const getQuestionsId = useCallback(async () => {
    const res = await axios.get(`/questions/${id}`);
    if (!res.data) return;
    setQuestion(res.data.question);
  }, [id]);
  const { asyncCallback: fetchData } = useAsyncCallback(getQuestionsId, []);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  const navigate = useNavigate();
  return (
    <Stack p={{ xs: 2, lg: 5 }} width={'100%'} gap={2}>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        gap={2.25}
        onClick={() => navigate('/dashboard')}
        width={{ xs: '100%', lg: 'fit-content' }}
        sx={{ cursor: 'pointer' }}>
        <img alt={'icon-back'} src={'/icons/icon_back_simple.svg'} />
        <Typography
          fontFamily={`'MyFont', sans-serif`}
          color={'#770BA1'}
          fontWeight={700}
          fontSize={{ xs: '24px', lg: '36px' }}>
          Back to Dashboard
        </Typography>
      </Stack>
      <Stack alignItems={'center'}>
        <Stack width={{ xs: '100%', lg: 'fit-content' }} pb={12.5}>
          {question && <BlogPost blog={question} />}
          <Stack pr={{ xs: 2.5, md: 0 }} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <ButtonStyled onClick={() => navigate(`/dashboard/answer/${id}`)}>
              <Stack alignItems={'center'}>
                <img
                  width={90}
                  height={72}
                  alt={'icon-add-record'}
                  src={'/icons/icon_add_record-no-bg.svg'}
                />
                <Typography color={'#fff'} fontWeight={700} fontSize={'24px'}>
                  Record Response
                </Typography>
              </Stack>
            </ButtonStyled>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ButtonStyled = styled(Button)(() => ({
  height: 120,
  width: '100%',
  background: '#770BA1 !important',
  boxShadow: '4px 4px 0px 0px #490065, 0px 4px 13px 4px rgba(119, 11, 161, 0.15)',
  borderRadius: '20px'
}));
