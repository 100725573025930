import { Button, Stack, styled, Typography } from '@mui/material';
import { useAuth } from 'contexts/user';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { BlogPost } from 'app/components/elements/BlogPost';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';

const SIZE_PAGE = 15;
/**
 * @returns Component Home Page
 */
export function HomePage() {
  const { user } = useAuth();
  const [dataPost, setDataPost] = useState<Questions[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchNextListPost = useCallback(async () => {
    const queryBy =
      searchParams.get('activeBy') === 'recommends' ? 'questions/recommends' : 'questions';
    const { data: res } = await axios.get(
      `/${queryBy}?page=${page}&size=${SIZE_PAGE}&tutorial=false`
    );
    const newData = res.result as Questions[];
    const dataConvert = newData.filter((item) => !item.isTutorial);
    setDataPost([...dataPost, ...dataConvert]);
    setPage((prevState) => prevState + 1);
    setHasMore(true);
    if (res.total <= SIZE_PAGE * page) {
      setHasMore(false);
    }
  }, [dataPost, page, searchParams]);

  const fetchListPost = useCallback(async () => {
    const queryBy =
      searchParams.get('activeBy') === 'recommends' ? 'questions/recommends' : 'questions';
    const { data: res } = await axios.get(`/${queryBy}?page=1&size=${SIZE_PAGE}`);
    const newData = res.result as Questions[];
    const dataConvert = newData.filter((item) => !item.isTutorial);
    setDataPost(dataConvert);
    setPage(2);
    if (res.total <= SIZE_PAGE) {
      setHasMore(false);
    }
    // eslint-disable-next-line
  }, [page, searchParams.get('activeBy')]);

  const { asyncCallback: fetchDataList } = useAsyncCallback(fetchListPost, []);
  useEffect(() => {
    fetchDataList();
  }, [searchParams.get('activeBy')]);

  return (
    <Stack width={'100%'} alignItems={'flex-start'}>
      {user?.role === 'STUDENT' && (
        <WrapperButton pt={2.5} pl={{ xs: 2.5, md: 7.25 }}>
          <ButtonStyled
            active={searchParams.get('activeBy') !== 'recommends'}
            onClick={() => setSearchParams({ activeBy: 'questions' })}>
            <TextButtonStyled active={searchParams.get('activeBy') !== 'recommends'}>
              Recent
            </TextButtonStyled>
          </ButtonStyled>
          <ButtonStyled
            active={searchParams.get('activeBy') === 'recommends'}
            onClick={() => setSearchParams({ activeBy: 'recommends' })}>
            <TextButtonStyled active={searchParams.get('activeBy') === 'recommends'}>
              Recommended
            </TextButtonStyled>
          </ButtonStyled>
        </WrapperButton>
      )}
      <Wrapper pt={2.55} pl={{ xs: 2.5, md: 7.25 }} width={{ xs: window.innerWidth, md: '100%' }}>
        {dataPost.length === 0 ? (
          <Stack />
        ) : (
          <InfiniteScroll
            style={{ overflow: 'unset' }}
            dataLength={dataPost.length}
            next={fetchNextListPost}
            hasMore={hasMore}
            loader={<CircularProgress />}>
            {dataPost.map((item) => (
              <BlogPost key={item.id} blog={item} />
            ))}
          </InfiniteScroll>
        )}
      </Wrapper>
    </Stack>
  );
}

const Wrapper = styled(Stack)(() => ({
  width: 'fit-content'
}));

const WrapperButton = styled(Stack)(() => ({
  flexDirection: 'row',
  marginBottom: '40px',
  width: '100%',
  background: '#d090d7ab'
}));

const ButtonStyled = styled(Button)(({ active }: { active: boolean }) => ({
  padding: '12px 16px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  background: !active ? '#D090D7' : '#FFF',
  '&:hover': {
    background: !active ? '#D090D7' : '#FFF',
    opacity: 1
  },
  minWidth: '170px'
}));

const TextButtonStyled = styled(Typography)(({ active }: { active: boolean }) => ({
  color: active ? '#D090D7' : '#FFF',
  fontSize: '16px'
}));
