import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { OptionsSubject } from 'app/pages/Introduction/components/FormInfo';
export const optionsSubject: OptionsSubject[] = [
  {
    label: 'Physics',
    value: '1',
    color: '#973DAD'
  },
  {
    label: 'Chemistry',
    value: '2',
    color: '#C15FD2'
  },
  {
    label: 'Biology',
    value: '3',
    color: '#48C2F9'
  },
  {
    label: 'Computer Science',
    value: '4',
    color: '#81B734'
  },
  {
    label: 'Mathematics',
    value: '5',
    color: '#AECC20'
  },
  {
    label: 'Language',
    value: '6',
    color: '#FFAD00'
  },
  {
    label: 'Art + Music',
    value: '7',
    color: '#FF8B24'
  },
  {
    label: 'Social Studies',
    value: '8',
    color: '#F22369'
  },
  {
    label: 'Business',
    value: '9',
    color: '#FF4095'
  },
  {
    label: 'Others',
    value: '10',
    color: '#A52A2A'
  }
];
export const renderListSubject = () => {
  return optionsSubject.map((item) => ({
    ...item,
    icon: <IconFlowerCustom subjectId={item.value} />
  }));
};
