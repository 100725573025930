import { Stack, Typography } from '@mui/material';
import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { renderColorSubject } from 'utils/renderColorSubject';

type TextSubjectIdProps = {
  subjectId: string;
  isEllipsis?: boolean;
};
export const TextSubjectId = ({ subjectId, isEllipsis }: TextSubjectIdProps) => {
  return (
    <Stack flexDirection={'row'} alignItems={'center'} gap={0.625}>
      <IconFlowerCustom subjectId={subjectId} />
      <Typography
        fontSize={'18px'}
        fontWeight={400}
        whiteSpace={'pre'}
        color={renderColorSubject(subjectId)?.color || '#0000'}
        width={{ xs: isEllipsis ? '120px' : 'unset', lg: 'unset' }}
        overflow={isEllipsis ? 'hidden' : 'unser'}
        textOverflow={isEllipsis ? 'ellipsis' : 'unset'}>
        {renderColorSubject(subjectId)?.label}
      </Typography>
    </Stack>
  );
};
